// Library Imports
import React, { useState, useEffect, useContext } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Button, ClickAwayListener, IconButton, Skeleton } from "@mui/material"
import {
  Person as PersonIcon,
  Business as BusinessIcon,
  Castle as CastleIcon,
  Check as CheckIcon,
  Construction as ConstructionIcon,
  ContentCopy as ContentCopyIcon,
  Launch as LaunchIcon,
  SettingsOutlined as SettingsOutlinedIcon,
} from "@mui/icons-material"
import { Can } from "@casl/react"

// Component Imports
import { AccountContext } from "../../helper/AccountContext"
import { TitleContext } from "../../helper/TitleContext"
import { ErrorContext } from "../../helper/AlertContext"
import { AbilityContext } from "../../helper/AbilityContext"
import Breadcrumb from "../items/Breadcrumb"
import TemplateDialog from "../items/TemplateDialog"
import { accountService, organizationService } from "../../api/services"

// Stylesheet Imports
import HeaderStyles from "../../styles/Header.module.css"

const breadcrumbMapping = {
  "/dashboard": [
    { name: "Home", link: "/dashboard" },
    { name: "Dashboard", link: "/dashboard" },
  ],
  "/ai-projects": [
    { name: "Home", link: "/dashboard" },
    { name: "AI Projects", link: "/ai-projects" },
  ],
  "/data": [
    { name: "Home", link: "/dashboard" },
    { name: "Data Sets", link: "/data" },
  ],
  "/logs": [
    { name: "Home", link: "/dashboard" },
    { name: "Logs", link: "/logs" },
  ],
  "/api-keys": [
    { name: "Home", link: "/dashboard" },
    { name: "API Keys", link: "/api-keys" },
  ],
  "/account": [
    { name: "Home", link: "/dashboard" },
    { name: "Settings", link: "/account" },
  ],
  "/app-directory": [
    { name: "Home", link: "/dashboard" },
    { name: "App Directory", link: "/app-directory" },
  ],
  // ... more paths
}
const UserAvatar = ({ imgUrl, height = 24, width = 24, alt = "user avatar" }) => {
  return (
    <>
      {imgUrl ? (
        <img className={HeaderStyles.avatar} src={imgUrl} height={height} width={width} alt={alt} />
      ) : (
        <div className={`${HeaderStyles.avatar} ${HeaderStyles.default}`} style={{ height, width }}>
          <PersonIcon style={{ fontSize: height - 2 }} />
        </div>
      )}
    </>
  )
}

export default function Header({ logOut, pageTitle }) {
  const errorContext = useContext(ErrorContext)
  const { title } = useContext(TitleContext)
  const { fetchAccountStatus } = useContext(AccountContext)
  const ability = useContext(AbilityContext)
  const [dialogState, setDialogState] = useState({ show: false, type: "" })
  const [userProfile, setUserProfile] = useState(null)
  const [showProjectDialog, setShowProjectDialog] = useState(false)
  const [organizationList, setOrganizationList] = useState([])
  const [personalOrgId, setPersonalOrgId] = useState("")
  const [currentOrgId, setCurrentOrgId] = useState("")
  const [localLoading, setLocalLoading] = useState(true)
  const location = useLocation()
  const navigate = useNavigate()
  const pathnames = location.pathname.split("/").filter((x) => x)
  const breadcrumbArray = []

  // Breadcrumb logic
  pathnames.forEach((value, index) => {
    const url = `/${pathnames.slice(0, index + 1).join("/")}`

    if (url.startsWith("/data/")) {
      if (index === 1) {
        const dataName = sessionStorage.getItem("dataName")
        breadcrumbArray.push({ name: dataName, link: url })
      } else if (index === 2) {
        const dataSourceName = location.state?.dataSourceName || "Data Source"
        breadcrumbArray.push({ name: dataSourceName, link: url })
      }
    } else if (url.startsWith("/ai-projects/")) {
      if (index === 1) {
        const dataName = sessionStorage.getItem("appName")
        breadcrumbArray.push({ name: dataName, link: url })
      }
    } else {
      const found = Object.keys(breadcrumbMapping).find((pattern) => {
        const regex = new RegExp(`^${pattern.replace(/:\w+/g, "\\w+")}$`)
        return regex.test(url)
      })

      if (found) {
        const result = breadcrumbMapping[found]
        if (typeof result === "function") {
          breadcrumbArray.push(result(value))
        } else {
          breadcrumbArray.push(...result)
        }
      } else if (index === 3) {
        // For the third path layer, extract the current title and url
        breadcrumbArray.push({ name: title || pageTitle, link: location.pathname })
      }
    }
  })

  const onChangeOrganization = async (value) => {
    setLocalLoading(true)
    try {
      await organizationService.getOrganizationList({ headers: { "Org-Id": value } })
      setCurrentOrgId(value)
      navigate(location.pathname === "/account" ? 0 : "/account")
    } catch (error) {
      console.error("Failed to change organization")
    }
    setLocalLoading(false)
    toggleDialog()
  }

  const toggleDialog = (type = "") => {
    const { show } = dialogState
    setDialogState({ show: !show, type })
  }
  const toggleProjectDialog = () => {
    setShowProjectDialog(!showProjectDialog)
  }

  useEffect(() => {
    const asyncUseEffect = async () => {
      try {
        const { data } = await accountService.getAccount()
        setUserProfile({ avatar_url: data.avatar_url, email: data.email })
      } catch (error) {
        errorContext.setError(true)
        errorContext.setErrorMsg(error.message)
      }
    }
    fetchAccountStatus((account) => {
      const cachedOrgId = localStorage.getItem("orgId")

      if (account.organizations) {
        const initialOrgId = account.organizations.find((org) => org.type === "p").id

        setOrganizationList(account.organizations.filter((org) => org.type !== "p"))
        setPersonalOrgId(initialOrgId)
        setCurrentOrgId(initialOrgId)
      }
      if (cachedOrgId) {
        setCurrentOrgId(cachedOrgId)
      }
    })

    asyncUseEffect()
  }, [])

  return (
    <div className={HeaderStyles.headerWrap}>
      <div className={HeaderStyles.profileContainer}>
        <div></div>
        <div className={HeaderStyles.actionContainer}>
          <Can I="create" a="Project" ability={ability}>
            <div style={{ position: "relative" }}>
              <Button
                variant="contained"
                onClick={() => {
                  toggleDialog("template")
                }}
              >
                Create Project
              </Button>
              {dialogState.show && dialogState.type === "template" && (
                <ClickAwayListener onClickAway={toggleDialog}>
                  <div className={HeaderStyles.templateContainer}>
                    <Button
                      onClick={() => {
                        navigate("/ai-projects/create")
                      }}
                    >
                      <ConstructionIcon />
                      Build Your Own
                    </Button>
                    <Button onClick={toggleProjectDialog}>
                      <ContentCopyIcon />
                      Use a Template
                    </Button>
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </Can>
          <IconButton
            color="primary"
            onClick={() => {
              navigate("/account")
            }}
          >
            <SettingsOutlinedIcon />
          </IconButton>
          <div>
            <div className={HeaderStyles.avatarContainer}>
              {(userProfile && currentOrgId) || !localLoading ? (
                <div onClick={() => toggleDialog("account")} style={{ display: "inline-flex" }}>
                  {currentOrgId === personalOrgId ? (
                    <div className={HeaderStyles.userName}>
                      <UserAvatar imgUrl={userProfile.avatar_url} height={36} width={36} />
                      <span>{userProfile.email}</span>
                    </div>
                  ) : (
                    <div className={HeaderStyles.userName}>
                      <div className={HeaderStyles.iconWhite}>
                        <BusinessIcon style={{ color: "#fff", fontSize: "1rem" }} />
                      </div>
                      <span>{organizationList.find((org) => org.id === currentOrgId)?.name}</span>
                    </div>
                  )}
                </div>
              ) : (
                <Skeleton variant="rounded" animation="wave" sx={{ width: 140, height: 36 }} />
              )}
              {dialogState.show && dialogState.type === "account" && (
                <ClickAwayListener onClickAway={toggleDialog}>
                  <div className="option">
                    <div className={HeaderStyles.orgContainer}>
                      <Button
                        sx={{
                          cursor: "default",
                          lineHeight: "normal",
                          fontWeight: "bold",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        disableRipple
                      >
                        Workspaces
                      </Button>
                      <Button
                        onClick={() => onChangeOrganization(personalOrgId)}
                        className={currentOrgId === personalOrgId ? "btn-disabled" : ""}
                      >
                        <UserAvatar imgUrl={userProfile.avatar_url} alt="personal avatar" />
                        <span className="text-nowrap">Personal</span>
                        {currentOrgId === personalOrgId && <CheckIcon style={{ color: "#24dd88" }} />}
                      </Button>
                      {organizationList.map((org) => (
                        <Button
                          key={org.id}
                          onClick={() => onChangeOrganization(org.id)}
                          className={currentOrgId === org.id ? "btn-disabled" : ""}
                        >
                          <div className={HeaderStyles.iconWhite} style={{ flex: "0 0 24px" }}>
                            {org.type === "a" ? (
                              <CastleIcon style={{ color: "#fff", fontSize: "0.8rem" }} />
                            ) : (
                              <BusinessIcon style={{ color: "#fff", fontSize: "0.8rem" }} />
                            )}
                          </div>
                          <span className="text-nowrap">{org.name}</span>
                          {currentOrgId === org.id && <CheckIcon style={{ color: "#24dd88" }} />}
                        </Button>
                      ))}
                    </div>
                    <a
                      href="https://help.vextapp.com/"
                      target="_blank"
                      rel="noreferrer noopener"
                      onClick={() => toggleDialog()}
                    >
                      <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                        <LaunchIcon style={{ fontSize: "0.8rem" }} /> Help Center
                      </div>
                    </a>
                    <a
                      href="https://vext.readme.io/reference/"
                      target="_blank"
                      rel="noreferrer noopener"
                      onClick={() => toggleDialog()}
                    >
                      <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                        <LaunchIcon style={{ fontSize: "0.8rem" }} /> API Reference
                      </div>
                    </a>
                    <a
                      href="https://discord.gg/zcRqYk4Tz2"
                      target="_blank"
                      rel="noreferrer noopener"
                      onClick={() => toggleDialog()}
                    >
                      <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                        <LaunchIcon style={{ fontSize: "0.8rem" }} /> Join Our Discord
                      </div>
                    </a>
                    <Link onClick={logOut}>Logout</Link>
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </div>
        </div>
        <TemplateDialog open={showProjectDialog} handleOnClose={toggleProjectDialog} />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {title || pageTitle ? (
          <>
            <h2>{title || pageTitle}</h2>
            <Breadcrumb breadcrumbArray={breadcrumbArray} />
          </>
        ) : (
          <>
            <Skeleton variant="rounded" animation="wave" width={160} height={40} sx={{ mb: "0.8rem" }} />
            <Skeleton variant="rounded" animation="wave" width={220} height={19} />
          </>
        )}
      </div>
    </div>
  )
}
