import { useMemo } from "react"
import { useLocation } from "react-router-dom"

import { APP_VERSION } from "./constants"

export const arrayToObject = (array) =>
  array.reduce((obj, { key, value }) => {
    if (key.trim() !== "") {
      obj[key.trim()] = value.trim()
    }
    return obj
  }, {})

export const objectToArray = (obj) =>
  Object.entries(obj).map(([key, value]) => ({ key: key.trim(), value: value.trim() }))

export const isFilePath = (path) => {
  try {
    const subPath = new URL(path).pathname.split("/").pop()
    return [".doc", ".docx", ".jpg", ".jpeg", ".png", ".tiff", ".xls", ".xlsx", ".pdf", ".ppt", ".pptx", ".txt"].some(
      (filename) => subPath.endsWith(filename),
    )
  } catch (error) {
    return false
  }
}

export const isValidUrl = (path) => {
  const urlRegex = /^(https:\/\/)([a-zA-Z0-9-]{1,63}\.){1,253}[a-zA-Z]{2,63}(\/[-a-zA-Z0-9@:%._+~#?&//=]*)?$/
  return urlRegex.test(path)
}

export const getAppVersionText = () => {
  let version = APP_VERSION || ""

  if (version && version.indexOf("0") === 0) {
    version = `Alpha ${version}`
  } else {
    version = `v${version}`
  }
  return version
}

export const getDatabaseIp = () => {
  const isCustom = localStorage.getItem("vext_enterprise")
  return isCustom ? "34.124.238.93" : process.env.REACT_APP_DB_ALLOW_CONNECTION
}

export const useQuery = () => {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

export const isEnterpriseUser = () => {
  const enterpriseNames = ["ntu", "test"]

  try {
    const targetName = new URL(document.location).searchParams.get("org")

    if (!targetName) return false
    return enterpriseNames.includes(targetName) ? targetName : false
  } catch (error) {
    return false
  }
}

export const createSource = (
  selectedSource,
  dataframeId,
  integrationFileName,
  integrationFileType,
  integrationFileId,
  integrationSiteId,
  integrationFolderId,
  integrationFilePath,
  uploadedFile,
  sourceUrl,
) => {
  const sourceTypeMapping = {
    text: "text",
    upload: "file",
    crawler_webpage: "cp",
    crawler_website: "cs",
    media: "media",
    google: "google",
    confluence: "confluence",
    notion: "notion",
    sharepoint: "sharepoint",
  }

  let freshnessInHours = 0
  let detail = {}

  if (uploadedFile) {
    return {
      dataframe: [dataframeId],
      name:
        uploadedFile.type === "file" || uploadedFile.type === "csv" || uploadedFile.type === "text"
          ? uploadedFile.fileName
          : "",
      source: uploadedFile.type === "text" ? "text" : "file",
      detail: {
        raw_path:
          uploadedFile.type === "file" || uploadedFile.type === "csv" || uploadedFile.type === "text"
            ? uploadedFile.url
            : "",
        datafreshness: freshnessInHours,
      },
      is_schedule_job: freshnessInHours === 0 ? false : true,
      is_continuity: false,
    }
  }

  switch (selectedSource) {
    case "crawler_webpage":
    case "crawler_website":
      detail = {
        type: selectedSource,
        context: sourceUrl,
        datafreshness: freshnessInHours,
      }
      break

    case "media":
      detail = {
        type: selectedSource,
        raw_url: sourceUrl,
        datafreshness: freshnessInHours,
      }
      break

    case "google":
      detail = {
        type: integrationFileType,
        id: integrationFileId,
        datafreshness: freshnessInHours,
      }
      break

    case "confluence":
      detail = {
        type: selectedSource,
        site_id: integrationSiteId,
        page_id: integrationFileId,
        datafreshness: freshnessInHours,
      }
      break

    case "notion":
      detail = {
        type: selectedSource,
        page_id: integrationFileId,
        datafreshness: freshnessInHours,
      }
      break

    case "sharepoint":
      detail = {
        type: integrationFileType,
        site_id: integrationSiteId,
        folder_id: integrationFolderId,
        item_id: integrationFileId,
        link: integrationFilePath,
        datafreshness: freshnessInHours,
      }
      break

    default:
      throw new Error("Unsupported source type")
  }

  return {
    dataframe: [dataframeId],
    name:
      selectedSource === "crawler_webpage" || selectedSource === "crawler_website" || selectedSource === "media"
        ? sourceUrl
        : integrationFileName,
    source: sourceTypeMapping[selectedSource],
    detail: detail,
    is_schedule_job: freshnessInHours === 0 ? false : true,
    is_continuity: false,
  }
}

export const formatJsonString = (jsonString) => {
  if (jsonString == null) return jsonString

  // Replace 'None' with 'null' and fix quotes
  const transformedString = jsonString
    .replace(/\bNone\b/g, "null")
    .replace(/(\{|,)\s*'([^']+?)'\s*:/g, '$1 "$2":')
    .replace(/:\s*'([^']*)'\s*(,|\})/g, ': "$1"$2')
    .replace(/'\s*,\s*'/g, '", "')
    .replace(/'\s*\]/g, '"]')
    .replace(/\[\s*'/g, '["')

  try {
    const jsonObject = JSON.parse(transformedString)
    
    // Define keys to exclude
    const excludedTopLevelKeys = ["long_polling", "rag_filter"]
    const excludedNestedKeys = ["what_you_want_vector_db"]
    
    // Filter the object
    const adjustedObject = Object.entries(jsonObject).reduce((acc, [key, value]) => {
      // Skip excluded top-level keys
      if (excludedTopLevelKeys.includes(key)) return acc
      
      // Handle custom_variables specially
      if (key === "custom_variables") {
        const filteredVars = Object.entries(value)
          .filter(([nestedKey]) => !excludedNestedKeys.includes(nestedKey))
          .reduce((vars, [k, v]) => ({ ...vars, [k]: v }), {})
          
        if (Object.keys(filteredVars).length > 0) {
          acc[key] = filteredVars
        }
        return acc
      }
      
      // Include all other keys
      acc[key] = value
      return acc
    }, {})

    return JSON.stringify(adjustedObject, null, 2)
  } catch (e) {
    console.error("Error parsing JSON:", e)
    return transformedString
  }
}
