import React, { useState, useEffect, useContext } from "react"
import { useNavigate, useParams, Link } from "react-router-dom"

// Component Imports
import { AuthContext } from "../helper/AuthProvider"
import { LoadingContext } from "../helper/LoadingContext"
import { SuccessContext, ErrorContext } from "../helper/AlertContext"
import { accountService, organizationService } from "../api/services"
import InputField from "../components/items/InputField"
import request from "../api/axios"
import fetchRequest from "../api/fetch"
import { isEnterpriseUser } from "../utils"
import { CUSTOM_ERR_MSG } from "../utils/constants"

// Library Imports
import Button from "@mui/material/Button"

export default function ActivateAccount({ setProfile }) {
  const { setAuth } = useContext(AuthContext)
  const loadingContext = useContext(LoadingContext)
  const successContext = useContext(SuccessContext)
  const errorContext = useContext(ErrorContext)
  const [user, setUser] = useState({})
  const [newPass, setNewPass] = useState("")
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [isNameExisted, setIsNameExisted] = useState(false)
  const [alreadyActivated, setAlreadyActivated] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false)
  const [finishedChecking, setFinishedChecking] = useState(false)
  const [inputTouched, setInputTouched] = useState({ firstname: false, lastname: false })
  const { userId } = useParams()

  const navigate = useNavigate()

  // Password validation
  useEffect(() => {
    if (newPass === confirmPassword && newPass.length >= 12) {
      setIsPasswordConfirmed(true)
    } else {
      setIsPasswordConfirmed(false)
    }

    if (newPass.length >= 12) {
      setIsValidPassword(true)
    } else {
      setIsValidPassword(false)
    }
  }, [newPass, confirmPassword])

  const handleBlur = (field) => {
    setInputTouched((prev) => ({
      ...prev,
      [field]: true,
    }))
  }

  // Login action
  const finalizeLogin = () => {
    localStorage.setItem("profile", JSON.stringify(user))
    document.cookie = "userLoggedIn=true;path=/;"
    setProfile(user)
    setAuth(true)
    navigate(`/dashboard`)
    loadingContext.setIsLoading(false)
  }

  // Set name and password
  const activateAccount = async () => {
    loadingContext.setIsLoading(true)
    try {
      let fullname = {
        first_name: firstname,
        last_name: lastname,
      }
      let password = {
        password: newPass,
      }
      await fetchRequest(`/account`, {
        method: "PUT",
        body: JSON.stringify(fullname),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      })
      await request.post(`/changepassword/${userId}`, password)

      successContext.setSuccess(true)
      successContext.setSuccessMsg("Your account has been successfully activated.")
      finalizeLogin()
    } catch (error) {
      if (error.response && error.response.data.text === "not correct user id") {
        errorContext.setError(true)
        errorContext.setErrorMsg(CUSTOM_ERR_MSG.activation_incorrect_userid)
      } else {
        errorContext.setError(true)
        errorContext.setErrorMsg(error.message)
      }
      loadingContext.setIsLoading(false)
    }
  }
  const activateUser = async () => {
    loadingContext.setIsLoading(true)
    try {
      const activationResponse = await request.post(`/activate/${userId}`)
      const userEmail = activationResponse.data.text.email

      let email = {
        email: userEmail,
      }

      let loginData = {
        email: userEmail,
        password: null,
        account_type: "email",
      }

      const response = await fetchRequest(`/check_account`, {
        method: "POST",
        body: JSON.stringify(email),
        headers: { "Content-Type": "application/json" },
        credentials: "omit",
      })

      if (response.status === 406) {
        if (isEnterpriseUser()) {
          localStorage.setItem("vext_enterprise", isEnterpriseUser())
        }
        await accountService.login(loginData)
        await organizationService.getOrganizationList()
        const accountInfo = await accountService.getAccount()

        setUser({
          firstName: accountInfo.data.first_name,
          lastName: accountInfo.data.last_name,
          email: accountInfo.data.email,
          profilePic: accountInfo.data.avatar_url,
          ipCountry: accountInfo.data.ip_country,
        })
        if (accountInfo.data.first_name && accountInfo.data.last_name) {
          setFirstname(accountInfo.data.first_name)
          setLastname(accountInfo.data.last_name)
          setIsNameExisted(true)
        }
      } else {
        throw new Error(response.data)
      }
    } catch (error) {
      if (error.response && error.response.data.text === "already activate") {
        setAlreadyActivated(true)
        errorContext.setError(true)
        errorContext.setErrorMsg(CUSTOM_ERR_MSG.activation_already_activated)
      } else {
        errorContext.setError(true)
        errorContext.setErrorMsg(error.message)
      }
    } finally {
      loadingContext.setIsLoading(false)
      setFinishedChecking(true)
    }
  }

  useEffect(() => {
    activateUser()
  }, [])

  return (
    <div className="login-wrap">
      <img className="login-logo" src="/images/vext_logo.png" alt="vext logo" />
      <div className="login-container">
        {finishedChecking ? (
          !alreadyActivated ? (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <p style={{ margin: "auto", fontSize: "1.2rem", fontWeight: "700" }}>Activate Your Account</p>
                {!isNameExisted && (
                  <>
                    <div>
                      <p style={{ fontSize: "0.6rem", fontWeight: "700", marginBottom: "0.3rem" }}>First Name</p>
                      <InputField
                        placeholder="First Name"
                        onChange={setFirstname}
                        onBlur={() => handleBlur("firstname")}
                        invalid={!firstname && inputTouched.firstname}
                        disabled={loadingContext.isLoading}
                      />
                    </div>
                    <div>
                      <p style={{ fontSize: "0.6rem", fontWeight: "700", marginBottom: "0.3rem" }}>Last Name</p>
                      <InputField
                        placeholder="Last Name"
                        onChange={setLastname}
                        onBlur={() => handleBlur("lastname")}
                        invalid={!lastname && inputTouched.lastname}
                        disabled={loadingContext.isLoading}
                      />
                    </div>
                  </>
                )}
                <div>
                  <p style={{ fontSize: "0.6rem", fontWeight: "700", marginBottom: "0.3rem" }}>Password</p>
                  <InputField
                    placeholder="Password"
                    type="password"
                    onChange={setNewPass}
                    invalid={!isValidPassword && newPass !== ""}
                    disabled={loadingContext.isLoading}
                  />
                  <p style={{ fontSize: "0.7rem", marginTop: "0.3rem" }}>At least 12 characters</p>
                </div>
                <div>
                  <p style={{ fontSize: "0.6rem", fontWeight: "700", marginBottom: "0.3rem" }}>Confirm Password</p>
                  <InputField
                    placeholder="Confirm Password"
                    type="password"
                    onChange={setConfirmPassword}
                    invalid={newPass !== confirmPassword && confirmPassword !== ""}
                    disabled={loadingContext.isLoading}
                    onPressEnter={(e) => {
                      if (isPasswordConfirmed) {
                        activateAccount(e)
                      }
                    }}
                  />
                </div>
                <Button
                  variant="contained"
                  style={{ width: "100%" }}
                  disabled={!isPasswordConfirmed || !firstname || !lastname || loadingContext.isLoading}
                  onClick={activateAccount}
                >
                  {loadingContext.isLoading ? "Loading..." : "Set Password"}
                </Button>
              </div>
            </>
          ) : (
            // Account already activated
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                textAlign: "center",
              }}
            >
              <p style={{ fontSize: "1.2rem", fontWeight: "700" }}>This account has already been activated.</p>
              <p style={{ justifyContent: "center" }}>
                Please login <Link to="/login">here</Link>
              </p>
            </div>
          )
        ) : (
          // Validating...
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              textAlign: "center",
            }}
          >
            <p style={{ justifyContent: "center", fontSize: "1.2rem", fontWeight: "700" }}>
              Validating... Please Wait.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
