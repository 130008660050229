import request from "../axios"

const service = "/databaseinfo"

const requests = {
  updateSchema: (params, payload) => {
    const { databaseId, tableName } = params
    return request.put(`${service}/${databaseId}/${tableName}`, payload)
  },
}

export default requests
