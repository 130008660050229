import React, { useEffect, useRef, useState } from "react"
import { InputAdornment, TextField } from "@mui/material"
import Tagify from "@yaireo/tagify"
import "@yaireo/tagify/dist/tagify.css"

import BasicTooltip from "./BasicTooltip"
import "../../styles/tagify.css"

export default function MixedTextArea({
  className = "no-wrap",
  disabled = false,
  id = "mix-textarea",
  initialValue, // Tagify is not a controlled component
  maxTags = Infinity,
  mLength,
  onBlur = () => {},
  onChange = () => {},
  placeholder = "",
  chipList = [],
  hiddenChip = [],
  hideEndAdornment = false,
  hideVariables = false,
}) {
  const [tempChipList, setTempChipList] = useState("")
  const [replacedValue, setReplacedValue] = useState("")
  const tagifyRef = useRef(null)
  const inputRef = useRef(null)

  const getTextContent = (htmlNodes) => {
    let string = ""

    for (const node of htmlNodes) {
      switch (node.nodeName) {
        case "#text":
          // remove zwsp, nbsp chars
          if ("\u200B" !== node.nodeValue && "\u00A0" !== node.nodeValue) {
            string += node.nodeValue.replace("\u200B", "").replace("\u00A0", "")
          }
          break
        case "TAG":
          string += "${" + node.textContent + "}"
          break
      }
    }
    return string
  }

  const handleChange = (event) => {
    const textContent = getTextContent(event.detail.tagify.DOM.input.childNodes)
    const replacedTextContent = textContent.replace(/[\u4e00-\u9fff]/g, "ff").replace(/\${(.*?)}/g, "$1")

    setReplacedValue(replacedTextContent)
    switch (event.type) {
      case "blur":
        onBlur(textContent)
        break
      case "focus":
        tagifyRef.current.userInput = true
        break
      case "keydown": {
        if (event.detail.event.keyCode !== 8 && replacedTextContent.length >= mLength) {
          tagifyRef.current.userInput = false
          tagifyRef.current.DOM.input.blur()
        }
        break
      }
    }
    onChange(textContent)
  }

  useEffect(() => {
    const tagify = new Tagify(inputRef.current, {
      pattern: /\//, // allow '$' or '/'
      mode: "mix",
      enforceWhitelist: true,
      duplicates: true,
      dropdown: {
        enabled: 0,
        maxItems: 100,
        includeSelectedTags: true,
      },
      maxTags,
      mixTagsInterpolator: ["${", "}"],
      originalInputValueFormat: (data) => {
        return data.value
      },
      keepInvalidTags: true,
      whitelist: [],
      callbacks: {
        input: handleChange, // input text
        change: handleChange, // add a tag
        remove: handleChange, // delete a tag
        keydown: handleChange,
        focus: handleChange,
        blur: handleChange,
        paste: handleChange,
        "dropdown:select": handleChange,
      },
      hooks: {
        beforePaste: function (content) {
          return new Promise((resolve, reject) => {
            const clipboardData = content.clipboardData
            const pastedData = clipboardData.getData("Text")
            const textContent = getTextContent(content.target.childNodes)
            const replacedTextContent = textContent.replace(/[\u4e00-\u9fff]/g, "ff").replace(/\${(.*?)}/g, "$1")

            if (replacedTextContent.length + pastedData.length > mLength) {
              reject()
            }
            resolve()
          })
        },
      },
    })
    // encode "<", ">" symbols to escape html string
    const escapeHtmlTags = initialValue.replace(/</g, "&lt;").replace(/>/g, "&gt;")

    tagifyRef.current = tagify
    tagifyRef.current?.parseMixTags(escapeHtmlTags)
    if (initialValue) {
      const replacedValue = initialValue.replace(/[\u4e00-\u9fff]/g, "ff").replace(/\${(.*?)}/g, "$1") // match unicode
      setReplacedValue(replacedValue)
    }
    onChange(initialValue)
  }, [initialValue])

  useEffect(() => {
    setTempChipList(chipList.join(","))
    if (tempChipList !== chipList.join(",")) {
      const escapeHtmlTags = initialValue.replace(/</g, "&lt;").replace(/>/g, "&gt;")

      tagifyRef.current.whitelist = chipList
      tagifyRef.current?.parseMixTags(escapeHtmlTags)
    }
  }, [chipList])
  useEffect(() => {
    return () => {
      if (tagifyRef.current) {
        tagifyRef.current.destroy()
      }
    }
  }, [])

  return (
    <>
      <TextField
        inputRef={inputRef}
        id={id}
        className={`mixedTextarea ${className}`}
        disabled={disabled}
        focused={false}
        placeholder={placeholder ? placeholder : `Type '/' in the input field for quick variable access.`}
        InputProps={{
          endAdornment: (
            <>
              {!hideEndAdornment && (
                <>
                  <InputAdornment position="end">{`${replacedValue.length} / ${mLength}`}</InputAdornment>
                </>
              )}
            </>
          ),
        }}
      />
      {!hideVariables && (
        <div style={{ display: "flex", marginTop: 8 }}>
          <span
            style={{
              alignItems: "flex-start",
              display: "flex",
              flex: "0 0 142px",
              fontSize: "0.8rem",
              gap: "0.2rem",
              height: 32,
            }}
          >
            Available Variables
            <BasicTooltip
              tooltip={
                <span>
                  {`These are the variables from your endpoint or results generated from other actions. Type '/' in the input field for quick variable access. `}
                  <a
                    style={{ color: "#fff" }}
                    href="https://help.vextapp.com/en/articles/9248817-what-is-variables-in-system-prompt"
                    target="blank"
                  >
                    Learn more.
                  </a>
                </span>
              }
            />
            :
          </span>
          <div
            style={{ display: "flex", fontSize: "0.8rem", gap: "0.3rem", flexWrap: "wrap", alignContent: "flex-start" }}
          >
            {chipList.filter((c) => !hiddenChip.includes(c)).join(", ")}
          </div>
        </div>
      )}
    </>
  )
}
