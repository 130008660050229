// Essential Imports
import React, { useContext, useEffect } from "react"

// Component Imports
import Chips from "../items/Chips"

// Library Imports
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Skeleton } from "@mui/material"

const DataSourceDetailImport = ({ type, rows, handleRowClick, content, isLoading }) => {
  if (type === "cs") {
    return isLoading ? (
      <Skeleton variant="rounded" animation="wave" width={"100%"} height={50} />
    ) : (
      <TableContainer component={Paper} style={{ boxShadow: "0px 2px 4px -1px rgba(61,61,61,0.6)" }}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Page</TableCell>
              <TableCell>Sync Status</TableCell>
              <TableCell>Updated Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                onClick={() => handleRowClick(row)}
                sx={{ "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)", cursor: "pointer" } }}
              >
                <TableCell>{row.context}</TableCell>
                <TableCell>
                  <Chips status={row.status} />
                </TableCell>
                <TableCell>{new Date(parseInt(row.update_time)).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  } else {
    return (
      <div className="dataSourceItem">
        {isLoading ? (
          <Skeleton variant="rounded" animation="wave" width={"50rem"} height={500} sx={{ mt: "1rem" }} />
        ) : (
          <textarea className="vextSourceResult" value={content} disabled />
        )}
      </div>
    )
  }
}

export default DataSourceDetailImport
