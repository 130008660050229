import React, { useContext } from "react"
import InputField from "../items/InputField"
import AnimatedCheck from "../items/AnimatedCheck"
import Button from "@mui/material/Button"
import BasicTooltip from "../items/BasicTooltip"

import { ErrorContext } from "../../helper/AlertContext"
import { isFilePath, isValidUrl } from "../../utils"

export default function DataDetailMedia({
  sourceUrl,
  setSourceUrl,
  urlValid,
  setUrlValid,
  dataValid,
  setDataValid,
  setPopupLoading,
}) {
  const errorContext = useContext(ErrorContext)

  const handleMediaChange = (value) => {
    setSourceUrl(value)
    setUrlValid(isValidUrl(value) && !isFilePath(value))
    setDataValid(false)
  }

  const handleCheckDomain = async () => {
    setPopupLoading(true)
    try {
      if (sourceUrl) {
        setDataValid(true)
        setSourceUrl(sourceUrl)
      }
      setPopupLoading(false)
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
      setUrlValid(false)
      setPopupLoading(false)
    }
  }

  return (
    <>
      <h5>
        Public Media URL {""}
        <BasicTooltip
          tooltip={
            <span>
              {`Enter a publicly accessible media (video or audio) URL that doesn't require authentication. See supported sources, `}
              <a
                style={{ color: "#fff" }}
                href="https://help.vextapp.com/en/articles/9393157-supported-sources-for-media-link"
                target="blank"
              >
                here.
              </a>
            </span>
          }
        />
      </h5>
      <div className="crawlerInput" style={{}}>
        <InputField
          type="text"
          value={sourceUrl}
          onChange={handleMediaChange}
          id="url-input"
          invalid={urlValid === false}
          placeholder="https://"
        />
        {urlValid && urlValid === true && !dataValid && (
          <Button variant="contained" onClick={handleCheckDomain}>
            Check
          </Button>
        )}
        {dataValid && <AnimatedCheck />}
      </div>
    </>
  )
}
