import axios from "axios"

import { isEnterpriseUser } from "../utils"
import accountService from "./services/account"

let currentOrgId = null

const service = axios.create({
  withCredentials: true,
  baseURL: `${process.env.REACT_APP_API_ROOT}/api/v2`,
})

service.interceptors.request.use((config) => {
  const noNeedOrgIdPaths = ["/check_account", "/create_account", "/forgot_password", "/login", "/changepassword"]
  const isCustom = localStorage.getItem("vext_enterprise")

  if (isCustom || isEnterpriseUser()) {
    // [2024-05-31] switch to custom api host
    if (isCustom === "test" || isEnterpriseUser() === "test") {
      config.baseURL = "https://apidevtest2.vextapp.com/api/v2"
    } else {
      config.baseURL = "https://apidev-ntu.vextapp.com/api/v2"
    }
  }
  // [2024-03-18] specific requests which NOT starting with /api/v2
  if (config.url.indexOf("/stripes/") === 0 || config.url === "/oauth/auth") {
    config.baseURL = config.baseURL.replace("/api/v2", "")
  }

  if (config.url.indexOf("/organization_list") === 0 && config.headers["Org-Id"]) {
    // 1. if this request has org-id header, it means user wants to switch organization
    currentOrgId = config.headers["Org-Id"]
  } else if (config.url.indexOf("/logout") === 0) {
    // 2. if user logs out, reset organization id to null
    currentOrgId = null
  } else if (!noNeedOrgIdPaths.some((path) => config.url.includes(path))) {
    // 3. if cannot get organization id, try to get from local storage
    if (!currentOrgId) {
      currentOrgId = localStorage.getItem("orgId")
    }
    if (currentOrgId) {
      config.headers = {
        ...config.headers,
        "Org-Id": currentOrgId,
      }
    }
  }

  if (currentOrgId) {
    localStorage.setItem("orgId", currentOrgId)
  }

  return config
})
service.interceptors.response.use(
  (response) => {
    // set personal organization id to the initial org-id header when logged in
    if (response.config.url.indexOf("/organization_list") === 0 && !currentOrgId && !localStorage.getItem("orgId")) {
      currentOrgId = response.data.results.find((org) => org.type === "p").id
    }

    return response
  },
  (error) => {
    if (error.response.status === 401) {
      setTimeout(() => {
        document.cookie = "userLoggedIn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
        window.location.href = "/login"
      }, 1500)
    } else if (error.response.status === 428) {
      // user has change account so cookie cannot mapping
      localStorage.removeItem("orgId")
      accountService.logout()
      setTimeout(() => {
        document.cookie = "userLoggedIn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
        window.location.href = "/login"
      }, 1500)
    }
    return Promise.reject(error)
  },
)

export default service
