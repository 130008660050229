import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { MenuItem, MenuList } from "@mui/material"
import styled from "@emotion/styled"

import { ErrorContext } from "../helper/AlertContext"
import { AccountContext } from "../helper/AccountContext"
import WorkspaceTabs from "../components/children/WorkspaceTabs"
import OrganizationTabs from "../components/children/OrganizationTabs"
import { accountService, governmentService, organizationService } from "../api/services"
import { useQuery } from "../utils"

const LeftMenuTitle = styled.h4`
  margin: 0;
`
const LeftMenuItem = styled(MenuItem)`
  padding: 0.5rem;
  &:hover,
  &.active {
    background-color: #3d3d3d0f;
  }
`
const TABS = [
  { value: "profile", name: "General" },
  { value: "usage", name: "Usage & Billing" },
  { value: "members", name: "Members" },
]

export default function Account() {
  const [hasOrganization, setHasOrganization] = useState(false)
  const [isPerson, setIsPerson] = useState(true)
  const [orgId, setOrgId] = useState(null) // orgId here is actually workspace ID
  const [visibleTab, setVisibleTab] = useState("")
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const { accountInfo } = useContext(AccountContext)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const query = useQuery()

  const fetchOrganization = async () => {
    try {
      await governmentService.getGovernment()
      setHasOrganization(true)
    } catch (error) {
      return
    }
  }
  const handleJoinWorkspace = async (token) => {
    try {
      await organizationService.acceptInvitation(token)
    } catch (error) {
      setError(true)
      setErrorMsg(error.response?.data?.text)
    } finally {
      navigate("/account", { replace: true })
    }
  }
  const getWorkspaceId = async () => {
    try {
      const { config } = await accountService.getAccount()
      const orgId = config.headers["Org-Id"]

      setOrgId(orgId)
    } catch (error) {
      setError(true)
      setErrorMsg("Can't get current workspace id.")
    }
  }

  useEffect(() => {
    if (accountInfo && orgId) {
      const personalOrgId = accountInfo.organizations.find((org) => org.type === "p")?.id
      setIsPerson(personalOrgId === orgId)
    }
  }, [accountInfo, orgId])
  useEffect(() => {
    const tabParam = searchParams.get("tab")

    if (!tabParam) {
      setSearchParams({ tab: "space-profile" })
    } else if (tabParam.startsWith("org-")) {
      setVisibleTab("org")
    } else if (tabParam.startsWith("space-")) {
      setVisibleTab("space")
    }
  }, [searchParams])
  useEffect(() => {
    const invitationToken = query.get("token")

    if (invitationToken) {
      handleJoinWorkspace(invitationToken)
    }
    getWorkspaceId()
    setSearchParams({ tab: "space-profile" })
    fetchOrganization()
  }, [])

  return (
    <section style={{ display: "flex", gap: "2rem" }}>
      <div style={{ flex: "0 0 180px" }}>
        {!isPerson && hasOrganization ? (
          <>
            <LeftMenuTitle>Organization</LeftMenuTitle>
            <MenuList>
              {TABS.map((tab) => (
                <LeftMenuItem
                  key={`org-${tab.value}`}
                  onClick={() => {
                    setSearchParams({ tab: `org-${tab.value}` })
                  }}
                  className={`org-${tab.value}` === searchParams.get("tab") ? "active" : ""}
                >
                  {tab.name}
                </LeftMenuItem>
              ))}
            </MenuList>
          </>
        ) : null}
        <LeftMenuTitle>Workspace</LeftMenuTitle>
        <MenuList>
          {TABS.map((tab) => {
            return tab.value === "members" && isPerson ? null : (
              <LeftMenuItem
                key={`space-${tab.value}`}
                onClick={() => {
                  setSearchParams({ tab: `space-${tab.value}` })
                }}
                className={`space-${tab.value}` === searchParams.get("tab") ? "active" : ""}
              >
                {!isPerson && tab.value === "usage" ? "Usage" : tab.name}
              </LeftMenuItem>
            )
          })}
        </MenuList>
      </div>
      {visibleTab === "org" ? <OrganizationTabs /> : null}
      {visibleTab === "space" ? <WorkspaceTabs /> : null}
    </section>
  )
}
