// Essential Imports
import React, { useContext, useEffect, useState } from "react"

// Component Imports
import { SuccessContext, ErrorContext } from "../../helper/AlertContext"
import LocalLoadingBar from "../items/LocalLoadingBar"
import ConfirmationDialog from "../items/ConfirmationDialog"
import MakeSnippet from "../items/MakeSnippet"

import { databaseService } from "../../api/services"

// Library Imports
import {
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
  Tooltip,
  Checkbox,
} from "@mui/material"
import { KeyboardArrowDown as KeyboardArrowDownIcon, Refresh as RefreshIcon } from "@mui/icons-material"

const DataSourceDetailDatabase = ({ id, tableNameList, tableSqlMapping, isLoading, fetchDb, handleDeleteColumn }) => {
  const [selected, setSelected] = useState([])
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [expandedRow, setExpandedRow] = useState(null)
  const [canResetSchema, setCanResetSchema] = useState(false)
  const [canUpdateSchema, setCanUpdateSchema] = useState(false)
  const [codeSnippet, setCodeSnippet] = useState("")
  const [codeSnippetKey, setCodeSnippetKey] = useState(new Date().getTime())
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [isUpdatingSchema, setIsUpdatingSchema] = useState(false)
  const successContext = useContext(SuccessContext)
  const errorContext = useContext(ErrorContext)

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableNameList.map((_, index) => index)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, index) => {
    event.stopPropagation()
    const selectedIndex = selected.indexOf(index)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
  }

  // Cancel delete
  const handleCancelDelete = () => {
    setDeleteDialogOpen(false)
  }
  const handleResetSchema = () => {
    // force re-render code snippet
    setCodeSnippetKey(new Date().getTime())
    setCodeSnippet(tableSqlMapping[expandedRow])
    setCanResetSchema(false)
    setCanUpdateSchema(false)
  }
  const handleUpdateSchema = async () => {
    setIsUpdatingSchema(true)
    try {
      await databaseService.updateSchema({ databaseId: id, tableName: expandedRow }, { [expandedRow]: codeSnippet })
      successContext.setSuccess(true)
      successContext.setSuccessMsg("Scheme updated successfully")
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.response.data.text)
    } finally {
      setIsUpdatingSchema(false)
    }
  }
  const handleChangeSchema = (event) => {
    if (!canResetSchema) {
      setCanResetSchema(true)
    }
    if (!canUpdateSchema) {
      setCanUpdateSchema(true)
    }
    setCodeSnippet(event.currentTarget.innerText)
  }
  const handleExpandRow = (event) => {
    const targetRow = event.currentTarget.getAttribute("value")
    targetRow === expandedRow ? setExpandedRow(null) : setExpandedRow(targetRow)
  }

  useEffect(() => {
    if (expandedRow) {
      setCodeSnippet(tableSqlMapping[expandedRow])
    }
  }, [expandedRow])

  return isLoading ? (
    <Skeleton variant="rounded" animation="wave" width={"100%"} height={50} />
  ) : (
    <>
      <div style={{ display: "flex", gap: "1rem", alignItems: "center", justifyContent: "flex-end" }}>
        {selected.length > 0 && (
          <>
            <p>
              {selected.length} {selected.length > 1 ? "tables" : "table"} selected.
            </p>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                setDeleteDialogOpen(true)
              }}
            >
              Delete
            </Button>
          </>
        )}
        <Button
          variant="outlined"
          onClick={async () => {
            setIsLoadingTable(true)
            await fetchDb(id)
            setIsLoadingTable(false) // Reset loading state after fetch
          }}
          sx={{
            maxWidth: "fit-content",
            minWidth: "fit-content",
            pl: "0.5rem",
            pr: "0.5rem",
          }}
        >
          <RefreshIcon />
        </Button>
        <>
          <Tooltip title="Coming soon.">
            <div>
              <Button variant="contained" disabled={true}>
                Add Table
              </Button>
            </div>
          </Tooltip>
        </>
      </div>
      <TableContainer
        component={Paper}
        style={{
          boxShadow: "0px 2px 4px -1px rgba(61,61,61,0.6)",
          marginTop: "1rem",
          position: "relative",
        }}
      >
        <LocalLoadingBar localLoading={isLoadingTable} />
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < tableNameList.length}
                  checked={tableNameList.length > 0 && selected.length === tableNameList.length}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell colSpan={2}>Table Names</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableNameList.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selected.indexOf(index) !== -1}
                      onChange={(event) => handleClick(event, index)}
                    />
                  </TableCell>
                  <TableCell onClick={handleExpandRow} value={row} style={{ cursor: "pointer", maxWidth: 300 }}>
                    <div style={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                      <span style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{row}</span>
                      <KeyboardArrowDownIcon color="primary" />
                    </div>
                  </TableCell>
                </TableRow>
                {expandedRow === row ? (
                  <TableRow>
                    <TableCell />
                    <TableCell colSpan={2} style={{ maxWidth: 300 }}>
                      <Collapse in={expandedRow === row}>
                        <MakeSnippet
                          key={codeSnippetKey}
                          title={<h4 style={{ display: "inline-block", margin: 0 }}>Schema</h4>}
                          tooltipText={`Adjust the SQL query or sample data to enhance the accuracy of LLM SQL outputs.`}
                          language="sql"
                          onChange={handleChangeSchema}
                          canEdit
                        >
                          {tableSqlMapping[expandedRow]}
                        </MakeSnippet>
                        <div style={{ display: "flex", gap: 12, justifyContent: "end" }}>
                          {canResetSchema ? (
                            <Button style={{ padding: "6px 12px" }} onClick={handleResetSchema}>
                              Reset
                            </Button>
                          ) : null}
                          <Button
                            style={{ padding: "6px 12px" }}
                            variant="contained"
                            disabled={!canUpdateSchema || isUpdatingSchema}
                            onClick={handleUpdateSchema}
                          >
                            Update
                          </Button>
                        </div>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                ) : null}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={deleteDialogOpen}
        handlePrimary={() => {
          handleDeleteColumn(id, selected)
          setDeleteDialogOpen(false)
          setSelected([])
        }}
        handleSecondary={handleCancelDelete}
        title="Confirm Delete"
        content="Are you sure you want to delete? This action cannot be undone."
        primaryButtonText="Delete"
        primaryButtonColor="error"
      />
    </>
  )
}

export default DataSourceDetailDatabase
