import request from "../axios"

const requests = {
  login: (payload) => {
    return request.post("/login", payload)
  },
  logout: () => {
    return request.post("/logout")
  },
  createAccount: (payload) => {
    return request.post("/create_account", payload)
  },
  getAccount: () => {
    return request.get(`/account`)
  },
  getTaskList: () => {
    return request.get(`/user_trace`)
  },
  updateTaskList: (payload) => {
    // warn: this PUT works as PATCH
    return request.put(`/user_trace`, payload)
  },
}

export default requests
