import React, { useContext, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Box, Button, Skeleton, TextField } from "@mui/material"

import { ErrorContext, SuccessContext } from "../../helper/AlertContext"
import { AccountContext } from "../../helper/AccountContext"
import LinearProgress from "../items/Progress"
import BasicTooltip from "../items/BasicTooltip"
import MemberRow from "../items/MemberRow"
import ConfirmationDialog from "../items/ConfirmationDialog"
import BillingSection from "../items/BillingSection"
import CreateMemberForm from "./CreateMemberForm"
import TabPanel from "../template/TabPanel"
import { accountService, governmentService, organizationService } from "../../api/services"
import { ROLES, CUSTOM_ERR_MSG, TABS, WORKSPACE_ROLES } from "../../utils/constants"

import AccountTabsStyles from "../../styles/AccountTabs.module.css"

export default function WorkspaceTabs() {
  const [value, setValue] = useState(0)
  const [creatingMemberForm, setCreatingMemberForm] = useState({})
  const [memberList, setMemberList] = useState([])
  const [deletingUser, setDeletingUser] = useState({})
  const [dialogState, setDialogState] = useState({ show: false, type: "" })
  const [isLocalLoading, setIsLocalLoading] = useState(false)
  const [isLoadingCard, setIsLoadingCard] = useState(false)
  const [isCreatingButtonDisabled, setIsCreatingButtonDisabled] = useState(false)
  const [isPerson, setIsPerson] = useState(true)
  const [roleOptions, setRoleOptions] = useState([])
  const [organizationInfo, setOrganizationInfo] = useState({})
  const [organizationMembers, setOrganizationMembers] = useState([])
  const [profile, setProfile] = useState({})
  const [currentWorkspace, setCurrentWorkspace] = useState({})
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const { setSuccess, setSuccessMsg } = useContext(SuccessContext)
  const { accountInfo, fetchAccountStatus } = useContext(AccountContext)
  const [searchParams] = useSearchParams()

  const handleFormChange = (payload) => {
    setCreatingMemberForm(payload)
  }
  const handleCreateMember = async () => {
    let receivers = creatingMemberForm.email.split(",")
    let modified_error = ""
    const roles_id = new Array(receivers.length).fill(creatingMemberForm.role)

    receivers = receivers.map((r) => r.trim())
    setIsLocalLoading(true)
    try {
      await organizationService.sendInvitationEmail({ receivers, roles_id })
      setSuccess(true)
      setSuccessMsg("Invitation email has sent.")
      toggleDialog()
      handleGetMemberList()
    } catch (error) {
      if (error.response.data.text === "organization for basic type more than one") {
        modified_error = CUSTOM_ERR_MSG.org_invite_more_than_one
      }
      setError(true)
      setErrorMsg(modified_error || error.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  const toggleDialog = (type = "") => {
    const { show } = dialogState
    setDialogState({ show: !show, type })
  }
  const handleGetMemberList = async () => {
    try {
      setIsLoadingCard(true)
      const { data } = await organizationService.getMemberList()
      const userList = []

      data.data.map((item) => {
        userList.push({
          id: item.id,
          name: `${item.first_name} ${item.last_name}`,
          email: item.email,
          status: item.role_status.status,
          role_id: item.role_status.id,
          is_owner: item.is_owner,
        })
      })
      setMemberList(userList)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLoadingCard(false)
    }
  }
  const handleGetRoleList = async (orgId) => {
    try {
      const { data } = await organizationService.getRoleList(orgId)
      const options = []

      ROLES.map((targetRole) => {
        data.roles.map((role) => {
          if (targetRole === role.group_name) {
            options.push({ label: WORKSPACE_ROLES[targetRole], value: role.id })
          }
        })
      })
      setRoleOptions(options)
      setOrganizationInfo(data.government)
      if (data.government?.id) {
        handleGetOrganizationMembers(data.government.id)
      }
    } catch (error) {
      setError(true)
      setErrorMsg(CUSTOM_ERR_MSG.account_get_role_failed)
    }
  }
  const handleGetOrganizationMembers = async (organizationId) => {
    try {
      const { data } = await governmentService.getMemberList(organizationId)
      setOrganizationMembers(
        data.map((item) => ({
          id: item.user.id,
          email: item.user.email,
          name: `${item.user.first_name} ${item.user.last_name}`,
        })),
      )
    } catch (error) {
      setError(true)
      setErrorMsg("Can't get organization members.")
    }
  }
  const handleReinvite = async (email, role) => {
    let modified_error = ""

    try {
      await organizationService.sendInvitationEmail({ receivers: [email], roles_id: [role] })
      setSuccess(true)
      setSuccessMsg("Invitation email has sent.")
    } catch (error) {
      if (error.message === "organization for basic type more than one") {
        modified_error = CUSTOM_ERR_MSG.org_invite_more_than_one
      } else {
        modified_error = error.message
      }
      setError(true)
      setErrorMsg(modified_error)
    }
  }
  const handleUpdateUser = async ({ userId, roleId }) => {
    try {
      const { data } = await organizationService.updateRole({ user_id: userId, role_id: roleId })
      const userList = []

      data.data.map((item) => {
        userList.push({
          id: item.id,
          name: `${item.first_name} ${item.last_name}`,
          email: item.email,
          status: item.role_status.status,
          role_id: item.role_status.id,
          is_owner: item.is_owner,
        })
      })
      setMemberList(userList)
      setSuccess(true)
      setSuccessMsg("User role has changed.")
    } catch (error) {
      setError(true)
      setErrorMsg(error.response?.data?.message)
    }
  }
  const handleDeleteUser = async () => {
    setIsLocalLoading(true)
    try {
      await organizationService.removeMember(deletingUser.id)
      setDeletingUser({})
      toggleDialog()
      handleGetMemberList()
    } catch (error) {
      setError(true)
      if (error.response.status === 403) {
        setErrorMsg(error.message)
      } else {
        setErrorMsg(error.response?.data?.message)
      }
    } finally {
      setIsLocalLoading(false)
    }
  }
  const onClickRemoveUser = (id) => {
    setDeletingUser({ id })
    toggleDialog("removeMember")
  }
  const fetchAccount = async () => {
    setIsLoadingCard(true)
    try {
      const { config, data } = await accountService.getAccount()
      const orgId = config.headers["Org-Id"]

      if (orgId) {
        setProfile({ ...data, org_id: orgId })
        fetchAccountStatus((account) => {
          const targetWorkspace = account.organizations.find((org) => org.id === orgId)
          const isPersonalWorkspace = account.personalOrganization.id === orgId

          setCurrentWorkspace(targetWorkspace)
          if (!isPersonalWorkspace) {
            handleGetMemberList()
            handleGetRoleList(orgId)
          }
        })
      }
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLoadingCard(false)
    }
  }

  useEffect(() => {
    setIsCreatingButtonDisabled(Object.values(creatingMemberForm).some((value) => !value))
  }, [creatingMemberForm])
  useEffect(() => {
    setIsPerson(currentWorkspace?.type === "p")
  }, [currentWorkspace])
  useEffect(() => {
    const tabParam = searchParams.get("tab")

    if (tabParam && TABS.workspace.includes(tabParam)) {
      const tabIndex = TABS.workspace.indexOf(tabParam)
      setValue(TABS.workspace[tabIndex])
    }
  }, [searchParams])
  useEffect(() => {
    fetchAccount()
  }, [])

  return (
    <>
      {isLoadingCard || !currentWorkspace.type ? (
        <Box className={AccountTabsStyles.accountContainer} style={{ height: 450 }}>
          <div className={AccountTabsStyles.loadingCard}>
            <Skeleton variant="rounded" animation="wave" height={26} width={200} />
            {[...Array(2)].map((_, index) => (
              <div key={index}>
                <Skeleton variant="rounded" animation="wave" height={20} width={120} />
                <Skeleton variant="rounded" animation="wave" height={40} style={{ marginTop: "0.5rem" }} />
              </div>
            ))}
          </div>
        </Box>
      ) : (
        <Box className={AccountTabsStyles.accountContainer}>
          <TabPanel value={value} index={TABS.workspace[0]}>
            <div className={AccountTabsStyles.tabPanelCard}>
              <h3>Workspace Info</h3>
              {organizationInfo?.name && (
                <>
                  <h4>Organization</h4>
                  <span style={{ fontSize: "0.8rem" }}>{organizationInfo.name}</span>
                </>
              )}
              <h4>Workspace Name</h4>
              <TextField value={currentWorkspace.type === "p" ? "Personal" : currentWorkspace.name} disabled />
              <h4>Workspace ID</h4>
              <TextField value={currentWorkspace.id} disabled />
            </div>
            <div className={AccountTabsStyles.tabPanelCard}>
              <h3>Your Info</h3>
              <h4>Full Name</h4>
              <TextField id="userName" value={`${profile.first_name} ${profile.last_name}`} disabled />
              <h4>Email</h4>
              <TextField id="userEmail" value={profile.email} disabled />
            </div>
          </TabPanel>
          <TabPanel value={value} index={TABS.workspace[1]}>
            <div className={AccountTabsStyles.tabPanelCard}>
              <h3>{isPerson ? "Usage & Billing" : "Usage"}</h3>
              {isPerson && (
                <BillingSection
                  planName={accountInfo.subscriptionPlan}
                  planStartTime={accountInfo.subscriptionStart}
                  planEndTime={accountInfo.subscriptionEnd}
                />
              )}
              <h4>Usage</h4>
              <div className={AccountTabsStyles.usageContainer}>
                <div>
                  <div className={AccountTabsStyles.usageItem}>
                    <p>
                      Credit Usage
                      <BasicTooltip
                        tooltip={
                          <span>
                            {`Total credits consumed in the current billing cycle. Credits are applied exclusively to LLM usage, with varying consumption rates depending on the specific LLM. `}
                            <a
                              style={{ color: "#fff" }}
                              href="https://help.vextapp.com/en/articles/9418796-what-is-credit-and-how-is-it-calculated"
                              target="blank"
                            >
                              Learn more.
                            </a>
                          </span>
                        }
                      />
                    </p>
                    <i style={{ fontSize: "0.7rem" }}>
                      {`${accountInfo.currentCredits?.toLocaleString()} / ${accountInfo.maxCredits?.toLocaleString()} Credits`}
                    </i>
                  </div>
                  <LinearProgress count={(accountInfo.currentCredits / accountInfo.maxCredits) * 100} />
                </div>
                <div>
                  <div className={AccountTabsStyles.usageItem}>
                    <p>
                      Data Storage
                      <BasicTooltip tooltip="The total amount of data storage used within this billing cycle. This is calculated across all of your AI projects/data." />
                    </p>
                    <p style={{ fontSize: "0.7rem" }}>
                      {`${accountInfo.currentStorage?.toLocaleString()} GB / ${accountInfo.maxStorage?.toLocaleString()} GB`}
                    </p>
                  </div>
                  <LinearProgress count={(accountInfo.currentStorage / accountInfo.maxStorage) * 100} />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={TABS.workspace[2]}>
            <div className={AccountTabsStyles.tabPanelCard}>
              <h3>Members</h3>
              <div style={{ marginTop: "1rem" }}>
                <Button variant="contained" onClick={() => toggleDialog("addMember")}>
                  Add member
                </Button>
              </div>
              <div className={AccountTabsStyles.memberTableLike}>
                {memberList.map((user, index) => (
                  <MemberRow
                    key={index}
                    name={user.name}
                    email={user.email}
                    isOwner={user.is_owner}
                    status={user.status}
                    userId={user.id}
                    role={user.role_id}
                    roleOptions={roleOptions}
                    handleReinvite={handleReinvite}
                    handleUpdateUser={handleUpdateUser}
                    handleRemoveUser={() => onClickRemoveUser(user.id)}
                  />
                ))}
              </div>
            </div>
          </TabPanel>
          <ConfirmationDialog
            open={dialogState.show && dialogState.type === "addMember"}
            handlePrimary={handleCreateMember}
            handleSecondary={toggleDialog}
            title="Add Member"
            content={
              <CreateMemberForm
                options={roleOptions}
                orgMemberList={organizationMembers}
                wsMemberList={memberList}
                onChange={handleFormChange}
              />
            }
            primaryButtonText={isLocalLoading ? "Adding..." : "Add member"}
            primaryButtonVariant="contained"
            secondaryButtonVariant="outlined"
            primaryButtonDisabled={isLocalLoading || isCreatingButtonDisabled}
          />
          <ConfirmationDialog
            open={dialogState.show && dialogState.type === "removeMember"}
            handlePrimary={() => handleDeleteUser()}
            handleSecondary={toggleDialog}
            title="Confirm Remove"
            content={
              <>
                <div>Are you sure you want to remove this member?</div>
                <div>This action cannot be undone.</div>
              </>
            }
            primaryButtonColor="error"
            primaryButtonText={isLocalLoading ? "Removing..." : "Remove"}
            primaryButtonDisabled={isLocalLoading}
          />
        </Box>
      )}
    </>
  )
}
