import React from "react"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import { Bar } from "react-chartjs-2"

import { CHART_COLOR_PALETTE } from "../../utils/constants"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BASIC_OPTIONS = {
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: {
        stepSize: 1,
        beginAtZero: true,
      },
    },
  },
}

const StackBar = ({ chartTitle = "", chartDataset = [], xAxisLabels = [] }) => {
  const options = {
    ...BASIC_OPTIONS,
    plugins: {
      title: {
        display: Boolean(chartTitle),
        text: chartTitle,
      },
      legend: {
        display: false,
      },
    },
  }
  const chartData = {
    labels: xAxisLabels,
    datasets: chartDataset.map((data, index) => ({
      ...data,
      backgroundColor: `${CHART_COLOR_PALETTE[index % CHART_COLOR_PALETTE.length]}b3`,
    })),
  }

  return <Bar options={options} data={chartData} />
}

export default StackBar
