import React, { useEffect, useState, useContext } from "react"
import { Button, IconButton, TextField, Stack, Skeleton } from "@mui/material"
import { Delete as DeleteIcon } from "@mui/icons-material"
import { Can } from "@casl/react"

import { AbilityContext } from "../../../helper/AbilityContext"
import { ErrorContext } from "../../../helper/AlertContext"
import LocalLoadingBar from "../../items/LocalLoadingBar"
import BasicTooltip from "../../items/BasicTooltip"
import VariableSection from "../../items/VariableSection"
import MixedTextArea from "../../items/MixedTextArea"
import ActionDrawerStyles from "./ActionDrawer.module.css"

const VARIABLE_LIMIT = 4

const VariableColumn = ({ availableVars, cid, column, handleDelete, handleEdit, handleChange }) => {
  const ability = useContext(AbilityContext)

  const handleOnBlur = (value) => {
    handleEdit({ value })
  }
  const handleOnChange = (value) => {
    handleChange(value)
  }

  return (
    <div style={{ display: "flex", gap: 8 }}>
      <TextField
        value={column.key}
        inputProps={{ maxLength: 50 }}
        onChange={(event) => handleEdit({ key: event.target.value })}
        placeholder={`Key ${cid}`}
        style={{ flex: "1 0 180px" }}
        error={column.key_error}
        disabled={!ability.can("update", "Project")}
      />
      <MixedTextArea
        chipList={availableVars}
        initialValue={column.value}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        mLength={200}
        placeholder={"Type '/' for quick variable access."}
        disabled={!ability.can("update", "Project")}
        hideEndAdornment
        hideVariables
      />
      <Can I="update" a="Project" ability={ability}>
        <div style={{ alignItems: "center", display: "flex", flex: "1 0 32px" }}>
          <IconButton onClick={() => handleDelete(column.key)} value={column.value}>
            <DeleteIcon style={{ fontSize: 16 }} />
          </IconButton>
        </div>
      </Can>
    </div>
  )
}

const OutputDrawer = ({ availableVars, formData, isLoadingForm, sequence, onSubmit = () => {} }) => {
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const ability = useContext(AbilityContext)
  const [paramList, setParamList] = useState([])
  const [isDatachanged, setIsDataChanged] = useState(false)
  const [initialParamList, setInitialParamList] = useState([])

  const isValidKey = (key) => {
    return /^[A-Za-z0-9_]+$/.test(key)
  }

  const paramAction = {
    add: () => {
      setParamList((prevValue) => [...prevValue, { key: "", value: "", key_error: false, value_error: false }])
    },
    edit: (index, input) => {
      setParamList((prevValue) =>
        prevValue.map((item, idx) => {
          if (idx === index) {
            if (typeof input.key === "string") {
              return { ...item, ...input, key_error: !input.key || !isValidKey(input.key) }
            }
            if (typeof input.value === "string") {
              return { ...item, ...input, value_error: !input.value }
            }
          }
          return item
        }),
      )
    },
    remove: (key) => {
      setParamList((prevValue) => {
        return prevValue.filter((item) => item.key !== key)
      })
    },
  }

  useEffect(() => {
    const initialParamListStr = JSON.stringify(initialParamList)
    const currentParamListStr = JSON.stringify(paramList)
    if (currentParamListStr !== initialParamListStr) {
      setIsDataChanged(true)
    } else {
      setIsDataChanged(false)
    }
  }, [paramList])

  const handleOnSubmit = () => {
    let hasError = false
    let isEmpty = false
    const payload = {}
    const newArray = paramList.map((v) => {
      const invalidKey = !isValidKey(v.key)
      const keyEmpty = !v.key
      const valueEmpty = !v.value
      if (keyEmpty || valueEmpty) {
        isEmpty = true
      } else if (invalidKey) {
        hasError = true
      }
      return { ...v, key_error: keyEmpty || invalidKey ? true : false, value_error: valueEmpty }
    })

    setParamList(newArray)

    if (hasError || isEmpty) {
      if (hasError) {
        setError(true)
        setErrorMsg("Spaces and special characters are not allowed.")
      } else if (isEmpty) {
        setError(true)
        setErrorMsg("Input cannot be empty.")
      }
    } else {
      paramList.forEach((v) => {
        if (v.key) {
          payload[v.key] = v.value
        }
      })
      onSubmit({ ouputId: formData.id, outputVariables: payload })
      setError(false)
    }
  }

  useEffect(() => {
    if (formData.id) {
      const variables = formData.output_variables

      if (variables && JSON.stringify(variables) !== "{}") {
        const initialParams = Object.keys(variables).map((item) => ({
          key: item,
          value: variables[item],
        }))
        setParamList(initialParams)
        setInitialParamList(initialParams)
      }
    }
  }, [formData])

  return (
    <>
      <Can I="update" a="Project" ability={ability}>
        <Button
          sx={{ position: "absolute", top: "1.25rem", right: "1.5rem", zIndex: 2 }}
          onClick={handleOnSubmit}
          variant="contained"
          disabled={isLoadingForm || !isDatachanged}
        >
          Save
        </Button>
      </Can>
      <section style={{ position: "relative" }}>
        <LocalLoadingBar localLoading={isLoadingForm} />
      </section>
      {isLoadingForm ? (
        <Stack spacing={2} sx={{ padding: "1.5rem 2rem" }}>
          <Skeleton variant="rounded" animation="wave" height={150} />
          <Skeleton variant="rounded" animation="wave" height={50} />
        </Stack>
      ) : (
        <div className={ActionDrawerStyles.main}>
          <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
            <h4>
              Custom Output Parameters ({paramList.length + 1}/{VARIABLE_LIMIT + 1})
              <BasicTooltip
                tooltip={
                  <span>
                    {`By setting custom output parameters, you can define the output structure using any variables from the LLM pipeline. Learn more `}
                    <a
                      style={{ color: "#fff" }}
                      href="https://help.vextapp.com/en/articles/9353168-configuring-custom-input-output-parameters"
                      target="blank"
                    >
                      here.
                    </a>
                  </span>
                }
              />
            </h4>
            <div style={{ display: "flex", gap: 8 }}>
              <TextField value="text" inputProps={{ maxLength: 50 }} style={{ flex: "1 0 180px" }} disabled />
              <MixedTextArea
                chipList={[`action_${sequence - 1}_output`]}
                initialValue={`\${action_${sequence - 1}_output}`}
                mLength={50}
                disabled
                hideEndAdornment
                hideVariables
              />
            </div>
            {paramList.map((item, index) => (
              <VariableColumn
                key={index}
                availableVars={availableVars}
                cid={index + 2}
                column={item}
                handleDelete={() => paramAction.remove(item.key)}
                handleEdit={(input) => paramAction.edit(index, input)}
                handleChange={() => {
                  setIsDataChanged(true)
                }}
              />
            ))}
            <div className={ActionDrawerStyles.variableContainer}>
              <span>
                Available Variables
                <BasicTooltip
                  tooltip={
                    <span>
                      {`These are the variables from your endpoint or results generated from other actions. Type '/' in the input field for quick variable access. `}
                      <a
                        style={{ color: "#fff" }}
                        href="https://help.vextapp.com/en/articles/9248817-what-is-variables-in-system-prompt"
                        target="blank"
                      >
                        Learn more.
                      </a>
                    </span>
                  }
                />
                :
              </span>
              <VariableSection chipList={availableVars} />
            </div>
          </div>
          {paramList.length >= VARIABLE_LIMIT ? null : (
            <Can I="update" a="Project" ability={ability}>
              <Button
                onClick={paramAction.add}
                className={ActionDrawerStyles.add}
                disabled={paramList.length + 1 >= VARIABLE_LIMIT + 1}
              >
                + Add New Parameter
              </Button>
            </Can>
          )}
        </div>
      )}
    </>
  )
}

export default OutputDrawer
