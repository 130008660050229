import { useEffect } from "react"

const IntercomApp = ({ appID = "hgxcfk8n", ...otherSettings }) => {
  useEffect(() => {
    // Function to load the Intercom script
    const loadIntercomScript = () => {
      const w = window
      const ic = w.Intercom
      if (typeof ic === "function") {
        ic("reattach_activator")
        ic("update", w.intercomSettings)
      } else {
        const d = document
        const i = function () {
          i.c(arguments)
        }
        i.q = []
        i.c = function (args) {
          i.q.push(args)
        }
        w.Intercom = i

        const l = () => {
          const s = d.createElement("script")
          s.type = "text/javascript"
          s.async = true
          s.src = `https://widget.intercom.io/widget/${appID}`
          const x = d.getElementsByTagName("script")[0]
          x.parentNode.insertBefore(s, x)
        }

        if (d.readyState === "complete") {
          l()
        } else if (w.attachEvent) {
          w.attachEvent("onload", l)
        } else {
          w.addEventListener("load", l, false)
        }
      }
    }

    // Call the function to load the script
    loadIntercomScript()

    // Initialize Intercom when the script is loaded
    window.Intercom("boot", {
      app_id: appID,
      ...otherSettings,
    })

    // Optional: Cleanup function to run when the component unmounts
    return () => {
      window.Intercom && window.Intercom("shutdown")
      // Any other cleanup tasks can be performed here
    }
  }, [appID, otherSettings]) // Dependency array, re-run the effect if these values change

  return null // This component does not render anything
}

export default IntercomApp
