import React, { useContext } from "react"
import InputField from "../items/InputField"
import AnimatedCheck from "../items/AnimatedCheck"
import Button from "@mui/material/Button"
import BasicTooltip from "../items/BasicTooltip"

import { ErrorContext } from "../../helper/AlertContext"
import { datasetService } from "../../api/services"
import { isFilePath, isValidUrl } from "../../utils"

export default function DataDetailCrawler({
  sourceUrl,
  setSourceUrl,
  urlValid,
  setUrlValid,
  dataValid,
  setDataValid,
  setPopupLoading,
}) {
  const errorContext = useContext(ErrorContext)

  const handleCrawlerChange = (value) => {
    setSourceUrl(value)
    setUrlValid(isValidUrl(value) && !isFilePath(value))
    setDataValid(false)
  }

  const handleCheckDomain = async () => {
    setPopupLoading(true)
    try {
      const response = await datasetService.checkCrawledDomain({ domain: sourceUrl })

      if (response.data && response.data.text) {
        setDataValid(true)
        setSourceUrl(response.data.text)
      }
      setPopupLoading(false)
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
      setUrlValid(false)
      setPopupLoading(false)
    }
  }

  return (
    <>
      <h5>
        Public URL {""}
        <BasicTooltip tooltip="Enter a publicly accessible pages that doesn't require authentication." />
      </h5>
      <div className="crawlerInput" style={{ marginBottom: "1rem" }}>
        <InputField
          type="text"
          value={sourceUrl}
          onChange={handleCrawlerChange}
          id="url-input"
          invalid={urlValid === false}
          placeholder="https://"
        />
        {urlValid && urlValid === true && !dataValid && (
          <Button variant="contained" onClick={handleCheckDomain}>
            Fetch
          </Button>
        )}
        {dataValid && <AnimatedCheck />}
      </div>
    </>
  )
}
