import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  Button,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  Stack,
  Skeleton,
} from "@mui/material"
import {
  ExpandMore as ExpandMoreIcon,
  Refresh as RefreshIcon,
  InfoOutlined as InfoOutlinedIcon,
} from "@mui/icons-material"
import { Can } from "@casl/react"

import BasicTooltip from "../../items/BasicTooltip"
import CustomChip from "../../items/ClickableChip"
import DiscreteSlider from "../../items/Slider"
import LocalLoadingBar from "../../items/LocalLoadingBar"
import SelectField from "../../items/SelectField"
import MixedTextArea from "../../items/MixedTextArea"
import Chips from "../../items/Chips"
import { ErrorContext } from "../../../helper/AlertContext"
import { AccountContext } from "../../../helper/AccountContext"
import { datasetService } from "../../../api/services"
import { AbilityContext } from "../../../helper/AbilityContext"
import { DATA_SOURCES } from "../../../utils/constants"

import ActionDrawerStyles from "./ActionDrawer.module.css"

const DatasetDrawer = ({ availableVars, formData, sequence, isLoadingForm, onSubmit, setUnsaved }) => {
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const { fetchAccountStatus, setSubPrompt } = useContext(AccountContext)
  const ability = useContext(AbilityContext)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isDatachanged, setIsDataChanged] = useState(false)
  const [dataList, setDataList] = useState()
  const [dataInput, setDataInput] = useState("")
  const [filterTag, setFilterTag] = useState([])
  const [filterIndex, setFilterIndex] = useState([])
  const [filterTimestamp, setFilterTimestamp] = useState([])
  const [tagInput, setTagInput] = useState([])
  const [indexInput, setIndexInput] = useState([])
  const [timestampInput, setTimestampInput] = useState("")
  const [sourceList, setSourceList] = useState([])
  const [tempSystemPrompt, setTempSystemPrompt] = useState("")
  const [systemPromptInput, setSystemPromptInput] = useState("")
  const [topkInput, setTopkInput] = useState(1)
  const [topkLimit, setTopkLimit] = useState(10)
  const [account, setAccount] = useState(null)
  const navigate = useNavigate()

  const handleOnBlur = (value) => {
    setSystemPromptInput(value)
  }
  const handleOnChange = (value) => {
    setTempSystemPrompt(value)
  }
  const handleDataChange = (event) => {
    setDataInput(event?.target.value)
  }
  const handleOnSubmit = () => {
    if (isDatachanged) {
      const newTagInput = tagInput.map((tag) => {
        if (availableVars.includes(tag)) {
          return "${" + tag + "}"
        }
        return tag
      })
      const newIndexInput = indexInput.map((index) => {
        if (availableVars.includes(index)) {
          return "${" + index + "}"
        }
        return index
      })
      const payload = {
        componentId: formData.componentId,
        datasetId: dataInput,
        topk: topkInput,
        rag_filter: { tag: newTagInput, index: newIndexInput, time: timestampInput },
        systemPrompt: systemPromptInput,
      }

      onSubmit(payload)
    } else {
      onSubmit()
    }
  }
  const handleGetData = async (datasetId) => {
    setIsLoading(true)
    try {
      const { data } = await datasetService.getDataset(datasetId)

      setSourceList(data.sources)
      data.filters?.tag ? setFilterTag(data.filters.tag) : setFilterTag([])
      data.filters?.index ? setFilterIndex(data.filters.index) : setFilterIndex([])
      data.filters?.time ? setFilterTimestamp(data.filters.time) : setFilterTimestamp([])
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLoading(false)
    }
  }
  const handleGetDataList = async () => {
    setIsLoading(true)
    try {
      const { data } = await datasetService.getDatasetList({ per_page: 100 })

      setDataList(data.results.map((result) => ({ label: result.name, value: result.id })))
      setIsLoading(false)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    }
  }
  const gotoDataDetailPage = () => {
    navigate(`/data/${dataInput}`)
  }

  useEffect(() => {
    if (formData.id) {
      setDataInput(formData.id)
      setSourceList(formData.sources)
      setSystemPromptInput(formData.systemPrompt)
      if (formData.params?.top_k) {
        setTopkInput(formData.params.top_k)
      }
      if (formData.params.rag_filter?.tag) {
        setTagInput(formData.params.rag_filter.tag.map((item) => item.replace(/^\${(.*?)\}$/, "$1")))
      }
      if (formData.params.rag_filter?.index) {
        setIndexInput(formData.params.rag_filter.index.map((item) => item.replace(/^\${(.*?)\}$/, "$1")))
      }
      if (formData.params.rag_filter?.time) {
        setTimestampInput(formData.params.rag_filter.time)
      }
    }
  }, [formData])
  useEffect(() => {
    if (dataInput) {
      // TODO: cache data source list
      handleGetData(dataInput)
    }
  }, [dataInput])
  useEffect(() => {
    if (topkInput > account?.maxReference) {
      setSubPrompt(true)
      setTopkInput(account.maxReference)
    }
  }, [topkInput])
  useEffect(() => {
    if (
      formData.id === dataInput &&
      formData.params?.top_k === topkInput &&
      formData.systemPrompt === tempSystemPrompt
    ) {
      setIsDataChanged(false)
    } else {
      setIsDataChanged(true)
    }
    setIsDisabled(!dataInput || !tempSystemPrompt)
  }, [dataInput, topkInput, tempSystemPrompt])
  useEffect(() => {
    setUnsaved(isDatachanged)
  }, [isDatachanged])
  useEffect(() => {
    handleGetDataList()
    fetchAccountStatus((account) => {
      setAccount(account)
      if (!["Free", "Starter", "Pro"].includes(account.subscriptionPlan)) {
        setTopkLimit(20)
      }
    })
  }, [])

  return (
    <>
      <section style={{ position: "relative" }}>
        <LocalLoadingBar localLoading={isLoading || isLoadingForm} />
      </section>
      {isLoading && !dataList ? (
        <Stack spacing={2} sx={{ padding: "1.5rem 2rem" }}>
          <Skeleton variant="rounded" animation="wave" height={70} />
          <Skeleton variant="rounded" animation="wave" height={130} />
          <Skeleton variant="rounded" animation="wave" height={65} />
          <Skeleton variant="rounded" animation="wave" height={200} />
        </Stack>
      ) : (
        <>
          <Can I="update" a="Project" ability={ability}>
            <Button
              sx={{ position: "absolute", top: "1.25rem", right: "1.5rem", zIndex: 2 }}
              onClick={handleOnSubmit}
              variant="contained"
              disabled={isDisabled || isLoadingForm || isLoading || !isDatachanged}
            >
              Save
            </Button>
          </Can>
          <div className={ActionDrawerStyles.main}>
            <div className={ActionDrawerStyles.flex}>
              <h4>
                Data Set
                <BasicTooltip tooltip="A container with a group of data that you want the LLM to find information from." />
              </h4>
              <SelectField
                options={dataList}
                value={dataInput}
                onChange={handleDataChange}
                disabled={isLoading || !ability.can("update", "Project")}
              />
            </div>
            <div className={ActionDrawerStyles.sourceTable}>
              <div>
                <h4 style={{ height: 40 }}>
                  Data Source
                  <BasicTooltip tooltip="Imported data under the selected data set." />
                </h4>
                <Can I="update" a="Project" ability={ability}>
                  <div style={{ display: "flex", gap: 8 }}>
                    <Button
                      variant="outlined"
                      sx={{ maxWidth: "fit-content", minWidth: "fit-content", pl: "0.5rem", pr: "0.5rem" }}
                      onClick={() => handleGetData(dataInput)}
                      disabled={isLoading}
                    >
                      <RefreshIcon />
                    </Button>
                    <Button variant="outlined" onClick={gotoDataDetailPage}>
                      Manage Data Set
                    </Button>
                  </div>
                </Can>
              </div>
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell style={{ width: 120 }}>Type</TableCell>
                      <TableCell style={{ width: 120 }}>Sync Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ overflowY: "auto" }}>
                    {sourceList.map((source) => (
                      <TableRow key={source.id}>
                        <TableCell style={{ maxWidth: 200 }}>{source.name}</TableCell>
                        <TableCell style={{ width: 120, textTransform: "capitalize" }}>
                          {DATA_SOURCES[source.detail.type] || source.source}
                        </TableCell>
                        <TableCell style={{ minWidth: 80 }}>
                          <Tooltip placement="top" title={source.fail_reason}>
                            <Button sx={{ padding: 0, textTransform: "none" }}>
                              <Chips
                                icon={source.status === "f" ? <InfoOutlinedIcon /> : null}
                                status={source.status || "r"}
                              />
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
            <div className={ActionDrawerStyles.flex}>
              <div style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                <h4>
                  Top K
                  <BasicTooltip tooltip="Determine how many results the AI will refer to per query. The higher it is the more comprehensive the response will be." />
                </h4>
                <span style={{ fontSize: "0.8rem", marginRight: 24 }}>{topkInput}</span>
              </div>
              <DiscreteSlider
                initialValue={topkInput}
                min={1}
                max={topkLimit}
                step={1}
                handleSave={setTopkInput}
                disabled={!ability.can("update", "Project")}
              />
            </div>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <h4>
                  Input
                  <BasicTooltip
                    tooltip={
                      <span>
                        {`Specify the keyword or phrase to search within your dataset for targeted and effective data retrieval. `}
                        <a
                          style={{ color: "#fff" }}
                          href="https://help.vextapp.com/en/articles/9268332-using-variables-in-vector-database-tools-and-function-calling"
                          target="blank"
                        >
                          Learn more.
                        </a>
                      </span>
                    }
                  />
                </h4>
                <Can I="update" a="Project" ability={ability}>
                  <IconButton
                    onClick={() => setSystemPromptInput("${payload}")}
                    sx={{ backgroundColor: "transparent", color: "#3d3d3d", display: "flex", gap: 0.5, padding: 0 }}
                  >
                    <RefreshIcon sx={{ fontSize: "0.9rem" }} />
                    <p style={{ fontSize: "0.7rem" }}>Load Default</p>
                  </IconButton>
                </Can>
              </div>
              <div style={{ marginTop: 12 }}>
                <MixedTextArea
                  className="row-6"
                  disabled={!ability.can("update", "Project")}
                  initialValue={systemPromptInput}
                  chipList={availableVars}
                  onBlur={handleOnBlur}
                  onChange={handleOnChange}
                  mLength={1000}
                />
              </div>
            </div>
            {/* <Accordion
              elevation={0}
              sx={{
                "&::before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h4>Advanced Filter</h4>
              </AccordionSummary>
              <AccordionDetails>
                <h5>Tag</h5>
                <div>
                  <Autocomplete
                    multiple
                    freeSolo
                    disableClearable
                    forcePopupIcon
                    onChange={(_, value) => {
                      setTagInput(value)
                    }}
                    options={availableVars.concat(filterTag)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index })
                        return <CustomChip label={option} key={key} tagProps={tagProps} />
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        placeholder={tagInput.length ? "" : "Select variable or type to add new."}
                        {...params}
                      />
                    )}
                    value={tagInput}
                  />
                </div>
                <h5>Index</h5>
                <div>
                  <Autocomplete
                    multiple
                    freeSolo
                    disableClearable
                    forcePopupIcon
                    onChange={(_, value) => {
                      setIndexInput(value)
                    }}
                    options={availableVars.concat(filterIndex)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index })
                        return <CustomChip label={option} key={key} tagProps={tagProps} />
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        placeholder={indexInput.length ? "" : "Select variable or type to add new."}
                        {...params}
                      />
                    )}
                    value={indexInput}
                  />
                </div>
                <h5>Timestamp</h5>
                <div>
                  <MixedTextArea
                    chipList={availableVars.concat(filterTimestamp)}
                    hiddenChip={filterTimestamp}
                    initialValue={timestampInput}
                    onBlur={(value) => {
                      setTimestampInput(value)
                    }}
                    onChange={() => {
                      setIsDataChanged(true)
                    }}
                    mLength={200}
                    placeholder={`Type '/' for quick variable access.`}
                    hideEndAdornment
                  />
                </div>
              </AccordionDetails>
            </Accordion> */}
            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
              <h4>
                Output Variable
                <BasicTooltip
                  tooltip={`The result generated from this action can be referred as the following variable in any future actions.`}
                />
              </h4>
              <p>{`action_${sequence}_output`}</p>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default DatasetDrawer
